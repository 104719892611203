import React from 'react'
import { getPage } from '../../api/Posts'
import { Banner } from '../../components/Banner/Banner'
import { HandleErrorState } from '../../components/HandleErrorState/HandleErrorState'
import { HandleLoadingState } from '../../components/HandleLoadingState/HandleLoadingState'
import { useApiCall } from '../../lib/hooks/useApiCall'
import { useRouter } from '../../lib/hooks/useRouter'

//styles
import './Page.scss'

export const Page = () => {
  const { match } = useRouter()
  //@ts-ignore
  const slug = match.params.slug

  const { data, loading, error } = useApiCall(getPage, [slug])

  return (
    <div className="Page">
      <HandleLoadingState loading={loading}>
        <HandleErrorState error={error}>
          <Banner backgroundImage={data?.image}>{data?.title}</Banner>
          <div className="container">
            <div className="About__content">
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.content || ''
                }}
              ></div>
            </div>
          </div>
        </HandleErrorState>
      </HandleLoadingState>
    </div>
  )
}
