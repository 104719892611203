import React from 'react'
import { useRouter } from '../../lib/hooks/useRouter'

//styles
import './ServiceItem.scss'

interface Props {
  image: string
  name: string
  slug: string
}

export const ServiceItem = (props: Props) => {
  const { history } = useRouter()
  const handleClick = () => {
    history.push(`/services/${props.slug}`)
  }
  return (
    <div className="ServiceItem" onClick={handleClick}>
      <div className="ServiceItem__image">
        {props.image && <img src={props.image} alt="Our services" />}
      </div>
      <p>{props.name}</p>
    </div>
  )
}
