/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Slider from 'react-slick'
import { Col, Row } from 'reactstrap'
import { getHome, Project } from '../../api/Posts'
import { ContactForm } from '../../components/ContactForm/ContactForm'
import { HandleErrorState } from '../../components/HandleErrorState/HandleErrorState'
import { HandleLoadingState } from '../../components/HandleLoadingState/HandleLoadingState'
import { HomeBanner } from '../../components/HomeBanner/HomeBanner'
import { ProjectItem } from '../../components/Projects/ProjectItem/ProjectItem'
import { ServiceItem } from '../../components/ServiceItem/ServiceItem'
import { PageTitle } from '../../components/shared/PageTitle/PageTitle'

import { Testimonial } from '../../components/Testimonial/Testimonial'
import { useApiCall } from '../../lib/hooks/useApiCall'

//styles
import './Home.scss'

const settingsTestimonials = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  centerMode: true,
  centerPadding: '100px',
  responsive: [
    {
      breakpoint: 768,
      settings: {
        centerPadding: '0px'
      }
    }
  ]
}

export const Home = () => {
  const { data, loading, error } = useApiCall(getHome)

  return (
    <div className="Home">
      <HandleLoadingState loading={loading}>
        <HandleErrorState error={error}>
          <HomeBanner backgroundImage={data?.banner.image}>
            <div className="HomeBanner__inner">
              <div className="container">
                <div className="HomeBanner__inner__content">
                  <h3>{data?.banner.title}</h3>
                  <p>{data?.banner.description}</p>
                  <a href={data?.banner.button.url}>
                    {data?.banner.button.title}
                  </a>
                </div>
              </div>
            </div>
          </HomeBanner>
          {data?.services && data?.services?.length > 0 && (
            <div className="Home__services">
              <div className="container">
                <PageTitle>Our Services</PageTitle>
                <Row>
                  {data?.services?.map((service) => {
                    return (
                      <Col xs="6" sm="6" lg="3" key={`service-${service.id}`}>
                        <ServiceItem
                          key={`home-service-${service.id}`}
                          name={service.name}
                          slug={service.slug}
                          image={service.image}
                        />
                      </Col>
                    )
                  })}
                </Row>
              </div>
            </div>
          )}

          <div className="Home__contact">
            <div className="container">
              <PageTitle>Contact us</PageTitle>
              <ContactForm />
            </div>
          </div>
          {data?.projects && data?.projects?.length > 0 && (
            <div className="Home__projects">
              <div className="container">
                <PageTitle>Our Projects</PageTitle>
                <Row>
                  {data?.projects.map((project: Project) => {
                    return (
                      <Col
                        xs="6"
                        sm="6"
                        md="6"
                        lg="3"
                        key={`project-${project.id}`}
                      >
                        <ProjectItem
                          image={project.image}
                          images={project.gallery}
                        />
                      </Col>
                    )
                  })}
                </Row>
              </div>
            </div>
          )}

          {data?.testimonials && data?.testimonials?.length > 0 && (
            <div className="Home__testimonials">
              <div className="container">
                <PageTitle>Testimonial</PageTitle>
                <Slider {...settingsTestimonials}>
                  {data?.testimonials.map((testimonial) => {
                    return (
                      <Testimonial
                        key={`testimonial-${testimonial.id}`}
                        image={testimonial.image}
                        name={testimonial.name}
                        text={testimonial.description}
                      ></Testimonial>
                    )
                  })}
                </Slider>
              </div>
            </div>
          )}
        </HandleErrorState>
      </HandleLoadingState>
    </div>
  )
}
