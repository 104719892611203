import React from 'react'
import { useScrollToTop } from './lib/hooks/useScrollToTop'

import { UIContextProvider } from './lib/context/UIContext/UIContextProvider'
import { ConfirmationContextProvider } from './lib/context/ConfirmationContext/ConfirmationContextProvider'

//Partials
import { Header } from './partials/Header/Header'
import { Footer } from './partials/Footer/Footer'
import { Routes } from './routes/Routes'

//Styles
import './App.scss'

function App() {
  useScrollToTop()
  return (
    <div className="App">
      <UIContextProvider>
        <ConfirmationContextProvider>
          <Header />
          <Routes />
          <Footer />
        </ConfirmationContextProvider>
      </UIContextProvider>
    </div>
  )
}

export default App
