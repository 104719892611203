import React, { useState } from 'react'
import { Spinner } from 'reactstrap'
import { getThemeResponse, Header, Footer } from '../../../api/Api'
import { useApiCall } from '../../hooks/useApiCall'
import { UIContext, UIContextType } from './UIContext'
const warningIcon = require('../../../assets/icons/warning.svg')

interface UIContextProviderProps {
  children: React.ReactNode | null
}

export interface UIContextProviderState {
  header?: Header
  footer?: Footer
}

export const UIContextProvider = (props: UIContextProviderProps) => {
  const [state, setState] = useState<UIContextProviderState>()
  const { data, loading, error } = useApiCall(getThemeResponse)

  const updateState = (state: UIContextProviderState) => {
    setState(state)
  }

  if (loading) {
    return (
      <div className="App__loading">
        <Spinner />
      </div>
    )
  }

  if (error) {
    return (
      <div className="App__error">
        <img src={warningIcon} alt="Warning" />
        <p>An error has occurred. Please try again!</p>
      </div>
    )
  }
  const context: UIContextType = {
    ...state,
    header: data?.header,
    footer: data?.footer,
    updateState
  }

  return (
    <UIContext.Provider value={context}>{props.children}</UIContext.Provider>
  )
}
