import { createContext, useContext } from 'react'
import { Footer, Header } from '../../../api/Theme'
import { UIContextProviderState } from './UIContextProvider'

export interface UIContextType {
  header?: Header
  footer?: Footer
  updateState: (value: UIContextProviderState) => void
}

const UIContextValues: UIContextType = {
  updateState: (value: UIContextProviderState) => {}
}

export const UIContext = createContext<UIContextType>(UIContextValues)

export const useUIContext = () => useContext(UIContext)
