import React from 'react'

//styles
import './Banner.scss'

interface Props {
  children: React.ReactNode
  backgroundImage?: string
}

export const Banner = (props: Props) => {
  return (
    <div className="Banner">
      <img src={props.backgroundImage} alt="Banner" />
      <h1>{props.children}</h1>
    </div>
  )
}
