import { apiRequest } from './Api'

export interface SocialMedia {
  icon?: string
  link?: string
}
export interface MenuItem {
  ID: number
  title: string
  url: string
  children?: MenuItem[]
}
export interface Header {
  logo: string
  number: string
  email: string
  socialMedia: SocialMedia[]
  menu: MenuItem[]
}
export interface Awards {
  image: string
  link: string
}

export interface Footer {
  logo: string
  shortAbout: string
  officeHours: string
  socialMedia: SocialMedia[]
  menu: MenuItem[]
  awards: Awards[]
}

export interface ThemeResponse {
  header: Header
  footer: Footer
}

export const getThemeResponse = async () =>
  apiRequest<undefined, ThemeResponse>('get', 'theme')
