import React from 'react'

//styles
import './Page404.scss'

export const Page404 = () => {
  return (
    <div className="Page404">
      <div className="container">
        <h1>
          Nothing found <b>404</b>!
        </h1>
      </div>
    </div>
  )
}
