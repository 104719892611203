import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { ContactForm } from '../../components/ContactForm/ContactForm'
import { PageTitle } from '../../components/shared/PageTitle/PageTitle'
//styles
import './Contact.scss'
interface Props {}

export const Contact = (props: RouteComponentProps<Props>) => {
  return (
    <div className="Contact">
      <div className="container">
        <PageTitle>Contact</PageTitle>
        <ContactForm />

        <iframe
          title="Google Map"
          className="Contact__map"
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3167.3689475596857!2d-73.9801609239685!3d40.766685034266025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDDCsDQ2JzAwLjEiTiA3M8KwNTgnMzkuMyJX!5e1!3m2!1sen!2s!4v1694453147390!5m2!1sen!2s"
          loading="lazy"
        ></iframe>
      </div>
    </div>
  )
}
