import React from 'react'

//styles
import './Testimonial.scss'

interface Props {
  image: string
  name: string
  text: string
}

export const Testimonial = (props: Props) => {
  return (
    <div className="Testimonial">
      <div className="Testimonial__left">
        <div className="Testimonial__image">
          <img src={props.image} alt=" Testiominals by customers" />
        </div>
        <h3>{props.name}</h3>
      </div>
      <div className="Testimonial__content">
        <div
          dangerouslySetInnerHTML={{
            __html: props.text || ''
          }}
        ></div>
      </div>
    </div>
  )
}
