import { apiRequest } from './Api'

export interface PostsResponse {
  maxPages: number
  currentPage: string
  total: number
}

export interface Service {
  id: number
  name: string
  slug: string
  image: string
}
export interface Button {
  title: string
  url: string
  target: string
}

export interface Project {
  id: number
  gallery: {
    image: string
  }[]
  name: string
  image: string
}
export interface Testimonial {
  id: number
  description: string
  name: string
  image: string
}
// Api calls from home Response
export interface HomeResponse {
  banner: {
    image: string
    title: string
    description: string
    button: Button
  }
  services: Service[]
  projects: Project[]
  testimonials: Testimonial[]
}

export interface ServiceResponse {
  id: number
  title: string
  thumbnail: string
  backgroundImage: string
  subtitle: string
  content: string
  projects: Project[]
}
export interface PageResponse {
  id: number
  title: string
  content: string
  image: string
}
export interface ContactResponse {
  address: string
  phone: string
  email: string
}

export interface ContactFormResponse {
  message: string
}

export interface ServicesResponse extends PostsResponse {
  services: Service[]
}

export interface ContactFormInput {
  fullName: string
  email: string
  phone?: string
  message: string
}

export const getServices = async () =>
  apiRequest<undefined, ServicesResponse>('get', 'services/1')

export const getHome = async () => {
  return apiRequest<undefined, HomeResponse>('get', 'home')
}

export const getProjects = async () => {
  return apiRequest<undefined, HomeResponse>('get', 'projects')
}

export const getService = async (slug: string) => {
  return apiRequest<string, ServiceResponse>('get', `service/${slug}`)
}

export const getPage = async (slug: string) => {
  return apiRequest<string, PageResponse>('get', `page/${slug}`)
}

export const getContact = async () => {
  return apiRequest<undefined, ContactResponse>('get', 'contact')
}

export const sentContact = async (values: ContactFormInput) => {
  return apiRequest<ContactFormInput, ContactFormResponse>(
    'post',
    'contact',
    values
  )
}
