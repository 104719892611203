import React from 'react'

//styles
import './HomeBanner.scss'

interface Props {
  children: React.ReactNode
  backgroundImage?: string
}

export const HomeBanner = (props: Props) => {
  return (
    <div className="HomeBanner">
      <img src={props.backgroundImage} alt="HomeBanner" />
      {props.children}
    </div>
  )
}
