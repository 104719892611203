import React from 'react'
import { Link } from 'react-router-dom'
import { useUIContext } from '../../../lib/context/UIContext/UIContext'

import './Nav.scss'

interface NavProps {
  isMenuOpen: boolean
}

export const Nav = (props: NavProps) => {
  const { header } = useUIContext()
  return (
    <nav className={`Nav ${props.isMenuOpen ? 'Navbar--open' : ''}`}>
      <ul>
        {header?.menu.map((item) => {
          return (
            <li key={`header-menu-item-${item.ID}`}>
              <Link to={item.url}>{item.title}</Link>
              {item?.children && item.children?.length > 0 && (
                <ul className="sub-menu">
                  {item.children?.map((subItem) => {
                    return (
                      <li key={`header-submenu-item-${subItem.ID}`}>
                        <Link to={subItem.url}>{subItem.title}</Link>
                      </li>
                    )
                  })}
                </ul>
              )}
            </li>
          )
        })}
      </ul>
    </nav>
  )
}
