import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Nav } from './Nav/Nav'
import { HeaderTop } from './HeaderTop/HeaderTop'
import { Hamburger } from '../../components/Hamburger/Hamburger'
import { useUIContext } from '../../lib/context/UIContext/UIContext'

//styles
import './Header.scss'

const logo = require('../../assets/images/logo.png')

export const Header = () => {
  const { header } = useUIContext()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = (value: boolean) => {
    setIsMenuOpen(value)
  }

  return (
    <div className="Header">
      <HeaderTop />
      <div className="HeaderMain">
        <div className="container">
          <div className="HeaderMain__inner">
            <div className="HeaderMain__left">
              <Link className="HeaderMain__logo" to={'/'}>
                <img src={header?.logo || logo} alt="Logo" />
              </Link>
            </div>
            <div className="HeaderMain__right">
              <Hamburger isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />

              <Nav isMenuOpen={isMenuOpen} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
