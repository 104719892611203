import React, { useEffect } from 'react'
import cs from 'classnames'
import { useHistory } from 'react-router'

//styles
import './Hamburger.scss'

interface Props {
  isMenuOpen: boolean
  toggleMenu: (value: boolean) => void
}

export const Hamburger = (props: Props) => {
  const history = useHistory()

  useEffect(() => {
    props.toggleMenu(false)
  }, [history.location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = () => {
    props.toggleMenu(!props.isMenuOpen)
  }

  return (
    <div
      className={cs('Hamburger', props.isMenuOpen && 'Hamburger--toggle')}
      onClick={handleClick}
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  )
}
