import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { useUIContext } from '../../lib/context/UIContext/UIContext'
//styles
import './Footer.scss'
const logo = require('../../assets/images/logo.png')

export const Footer = () => {
  const { footer } = useUIContext()

  return (
    <div className="Footer">
      <div className="container">
        <div className="Footer__inner">
          <Row>
            <Col xs="12" md="6" lg="4">
              <div className="Footer__about">
                <Link to={'/'}>
                  <img src={footer?.logo || logo} alt="Logo" />
                </Link>
                <p>{footer?.shortAbout}</p>
              </div>
            </Col>
            <Col xs="12" md="12" lg="1"></Col>
            <Col
              xs={{
                size: '12'
              }}
              md={{
                size: '3'
              }}
            >
              <div className="Footer__menu">
                <span>MENU</span>
                <ul>
                  {footer?.menu.map((item) => {
                    return (
                      <li key={`footer-menu-${item.ID}`}>
                        <Link to={item.url}>{item.title}</Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </Col>
            <Col xs="12" md="6" lg="4">
              <div className="Footer__info">
                <div className="Footer__hours">
                  <span>OFFICE HOURS</span>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: footer?.officeHours || ''
                    }}
                  ></div>
                </div>
                <div className="Footer__social">
                  {footer?.socialMedia?.map(({ link, icon }, index) => {
                    return (
                      <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={`footer-social-media-${index}`}
                      >
                        <img src={icon} alt="icon" />
                      </a>
                    )
                  })}
                </div>
              </div>
            </Col>

            {/* <Col xs="12" md="6" lg="3">
              <div className="Footer__awards">
                <span>AWARDS</span>
                <div className="Footer__awards__images">
                  {footer?.awards.map((award, index) => {
                    return (
                      <a
                        href={award.link}
                        key={`awards-${index}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={award.image} alt="Home Advisor" />
                      </a>
                    )
                  })}
                </div>
              </div>
            </Col> */}
          </Row>
        </div>
      </div>
      <div className="Footer__copyright">
        <div className="container">
          <p>
            All rights reserved &copy; LL Brothers GC -{' '}
            {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </div>
  )
}
