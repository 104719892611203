/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useUIContext } from '../../../lib/context/UIContext/UIContext'

import './HeaderTop.scss'

export const HeaderTop = () => {
  const { header } = useUIContext()
  return (
    <div className="HeaderTop">
      <div className="container">
        <div className="HeaderTop__inner">
          <div className="HeaderTop__left">
            <a href={`tel:${header?.number}`}>
              Call: <b>{header?.number}</b>
            </a>
            <a href={`mailto:${header?.email}`}>
              email: <b>{header?.email}</b>
            </a>
          </div>
          <div className="HeaderTop__right">
            {header?.socialMedia?.map(({ link, icon }, index) => {
              return (
                <a
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={`header-social-media-${index}`}
                >
                  <img src={icon} alt="icon" />
                </a>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
