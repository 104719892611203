import { useFormik } from 'formik'
import * as Yup from 'yup'
import React, { useState } from 'react'
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap'

//styles
import './ContactForm.scss'
import { getContact, sentContact } from '../../api/Posts'
import { useApiCall } from '../../lib/hooks/useApiCall'
import { HandleLoadingState } from '../HandleLoadingState/HandleLoadingState'
import { HandleErrorState } from '../HandleErrorState/HandleErrorState'

const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
const mapPin = require('../../assets/icons/pin.svg')
const phone = require('../../assets/icons/phone.svg')
const mailbox = require('../../assets/icons/mailbox.svg')

// Check for validation
const ContactFormSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email!')
    .required('Email is required'),
  fullName: Yup.string().required('Full Name  is required'),
  phone: Yup.string().matches(phoneRegex, 'Please enter a valid phone number!'),
  message: Yup.string().required('Message is required')
})

export interface ContactFormError {
  code: string
  message: string
  data: { errors: { field: string; message: string }[] }
}

export const ContactForm = () => {
  const {
    data: fetchData,
    loading: fetchLoading,
    error: fetchError
  } = useApiCall(getContact)
  const [error, setError] = useState<string | undefined>(undefined)
  const [success, setSuccess] = useState<string | undefined>(undefined)

  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      phone: '',
      message: ''
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ContactFormSchema,
    onSubmit: async (values, formikHelpers) => {
      try {
        setError(undefined)
        setSuccess(undefined)
        const res = await sentContact(values)
        setSuccess(res.message)
        formikHelpers.resetForm()
      } catch (error) {
        setError('There was a problem submiting form, please try again!')
      }
    }
  })

  return (
    <div className="ContactForm">
      <HandleLoadingState loading={fetchLoading}>
        <HandleErrorState error={fetchError}>
          <Row>
            <Col xs="12" md="12" lg="6">
              <div className="Contact__content">
                <div className="Contact__content__item">
                  <img src={mapPin} alt="Pin" />
                  <p>
                    Address:
                    <b
                      dangerouslySetInnerHTML={{
                        __html: fetchData?.address || ''
                      }}
                    ></b>
                  </p>
                </div>
                <div className="Contact__content__item">
                  <img src={phone} alt="Telephone number" />
                  <p>
                    Phone:
                    <a href={`tel:${fetchData?.phone}`}>
                      <b>{fetchData?.phone}</b>
                    </a>
                  </p>
                </div>
                <div className="Contact__content__item">
                  <img src={mailbox} alt="Mailbox"></img>
                  <p>
                    Email:
                    <a href={`mailto:${fetchData?.email}`}>
                      <b>{fetchData?.email}</b>
                    </a>
                  </p>
                </div>
              </div>
            </Col>
            <Col xs="12" md="12" lg="6">
              <Form onSubmit={formik.handleSubmit}>
                <FormGroup>
                  <Label for="fullName">Full Name</Label>
                  <Input
                    type="text"
                    name="fullName"
                    id="fullName"
                    placeholder="Full Name"
                    value={formik.values.fullName}
                    invalid={
                      !!formik.errors.fullName && formik.touched.fullName
                    }
                    onChange={formik.handleChange}
                  />
                  <FormFeedback>{formik.errors.fullName}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={formik.values.email}
                    invalid={!!formik.errors.email && formik.touched.email}
                    onChange={formik.handleChange}
                  />
                  <FormFeedback>{formik.errors.email}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="phone">Phone</Label>
                  <Input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="Phone Number"
                    value={formik.values.phone}
                    invalid={!!formik.errors.phone && formik.touched.phone}
                    onChange={formik.handleChange}
                  />
                  <FormFeedback>{formik.errors.phone}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="message">Message</Label>
                  <Input
                    type="textarea"
                    name="message"
                    id="message"
                    placeholder="Your message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    invalid={!!formik.errors.message && formik.touched.message}
                  />
                  <FormFeedback>{formik.errors.message}</FormFeedback>
                </FormGroup>
                {error && <p className="ContactForm__error">{error} </p>}
                {success && <p className="ContactForm__success">{success} </p>}
                <Button
                  className="ContactForm__btn"
                  onClick={() => formik.handleSubmit()}
                >
                  Submit
                </Button>
              </Form>
            </Col>
          </Row>
        </HandleErrorState>
      </HandleLoadingState>
    </div>
  )
}
