import React from 'react'

//styles
import './PageTitle.scss'

interface Props {
  children: string
}

export const PageTitle = (props: Props) => {
  return (
    <div className="PageTitle">
      <h1>{props.children}</h1>
    </div>
  )
}
