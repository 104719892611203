import React from 'react'
import { RouteProps, Switch } from 'react-router-dom'

import { PublicRoute } from './PublicRoute'

import { Home } from '../pages/Home/Home'
import { Page404 } from '../pages/Page404/Page404'
import { Page } from '../pages/Page/Page'
import { Contact } from '../pages/Contact/Contact'
import { Projects } from '../pages/Projects/Projects'
import { Service } from '../pages/Service/Service'

export enum RouteType {
  PUBLIC,
  PRIVATE,
  RESTRICTED
}
interface AppRoute extends RouteProps {
  type?: RouteType
}
export const AppRoutes: AppRoute[] = [
  // Public Routes
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: '/',
    component: Home
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: 'page/:slug',
    component: Page
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: 'projects',
    component: Projects
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: 'contact',
    component: Contact
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: 'services/:slug',
    component: Service
  }
]

export const Routes = () => {
  return (
    <Switch>
      {AppRoutes.map((r) => {
        const { type, path, ...rest } = r
        return <PublicRoute {...rest} key={`${r.path}`} path={`/${r.path}`} />
      })}
      <PublicRoute component={Page404} />
    </Switch>
  )
}
