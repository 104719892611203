import React, { useState } from 'react'
import { Modal } from '../../shared/Modal/Modal'
import Slider from 'react-slick'

//styles
import './ProjectItem.scss'

interface ProjectItemProps {
  image: string
  images?: { image: string }[]
}
const zoomIcon = require('../../../assets/icons/zoomIcon.svg')
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true
}
export const ProjectItem = (props: ProjectItemProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleClick = () => {
    setIsOpen(!isOpen)
  }
  return (
    <div className="ProjectItem">
      <div className="ProjectItem__box" onClick={handleClick}>
        <img src={props.image} alt="Modal" />
        <div className="ProjectItem__box__hover">
          <img src={zoomIcon} alt="Project Item menu" />
        </div>
      </div>
      <Modal isOpen={isOpen} toggle={handleClick}>
        <Slider {...settings}>
          {props.images?.map((item, index) => {
            return (
              <div key={`gallery-${item.image}-${index}`}>
                <img src={item.image} alt="" />
              </div>
            )
          })}
        </Slider>
      </Modal>
    </div>
  )
}
