import React from 'react'
import { Col, Row } from 'reactstrap'
import { getHome, Project } from '../../api/Posts'
import { Banner } from '../../components/Banner/Banner'
import { HandleErrorState } from '../../components/HandleErrorState/HandleErrorState'
import { HandleLoadingState } from '../../components/HandleLoadingState/HandleLoadingState'
import { ProjectItem } from '../../components/Projects/ProjectItem/ProjectItem'
import { useApiCall } from '../../lib/hooks/useApiCall'

//styles
import './Projects.scss'

interface Props {}

export const Projects = (props: Props) => {
  const { data, loading, error } = useApiCall(getHome)

  return (
    <div className="Projects">
      <HandleLoadingState loading={loading}>
        <HandleErrorState error={error}>
          <Banner
            backgroundImage={
              'https://images.unsplash.com/photo-1522708323590-d24dbb6b0267?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1170&q=80'
            }
          >
            Our Projects
          </Banner>
          <div className="Project__list">
            <div className="container">
              <Row>
                {data?.projects.map((project: Project) => {
                  return (
                    <Col
                      xs="6"
                      sm="6"
                      md="6"
                      lg="3"
                      key={`project-${project.id}`}
                    >
                      <ProjectItem
                        image={project.image}
                        images={project.gallery}
                      />
                    </Col>
                  )
                })}
              </Row>
            </div>
          </div>
        </HandleErrorState>
      </HandleLoadingState>
    </div>
  )
}
