import React from 'react'

import { Col, Row } from 'reactstrap'
import { getService } from '../../api/Posts'
import { Banner } from '../../components/Banner/Banner'
import { ContactForm } from '../../components/ContactForm/ContactForm'
import { HandleErrorState } from '../../components/HandleErrorState/HandleErrorState'
import { HandleLoadingState } from '../../components/HandleLoadingState/HandleLoadingState'
import { ProjectItem } from '../../components/Projects/ProjectItem/ProjectItem'
import { PageTitle } from '../../components/shared/PageTitle/PageTitle'
import { useApiCall } from '../../lib/hooks/useApiCall'
import { useRouter } from '../../lib/hooks/useRouter'

//styles
import './Service.scss'

export const Service = () => {
  const { match } = useRouter()
  //@ts-ignore
  const slug = match.params.slug

  const { data, loading, error } = useApiCall(getService, [slug])

  return (
    <div className="Service">
      <HandleLoadingState loading={loading}>
        <HandleErrorState error={error}>
          <Banner backgroundImage={data?.backgroundImage}>{data?.title}</Banner>
          <div className="Service__wrapper">
            <div className="container">
              <Row>
                <Col xs="12" md="6" lg="6">
                  <div className="Service__image">
                    <img src={data?.thumbnail} alt="Background" />
                  </div>
                </Col>
                <Col xs="12" md="6" lg="6">
                  <div className="Service__content">
                    <h3>{data?.subtitle}</h3>
                    <p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data?.content || ''
                        }}
                      ></div>
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>

          <div className="Service__project">
            <div className="container">
              <PageTitle>Related Projects</PageTitle>
              <Row>
                {data?.projects.map((project) => {
                  return (
                    <Col
                      xs="6"
                      md="6"
                      lg="3"
                      key={`service-project-${project.id}`}
                    >
                      <ProjectItem
                        image={project.image}
                        images={project.gallery}
                      />
                    </Col>
                  )
                })}
              </Row>
            </div>
          </div>

          <div className="Service__contact">
            <div className="container">
              <PageTitle>Contact us</PageTitle>
              <ContactForm />
            </div>
          </div>
        </HandleErrorState>
      </HandleLoadingState>
    </div>
  )
}
