import React from 'react'
import { Spinner } from 'reactstrap'
import './HandleLoadingState.scss'

interface Props {
  loading?: boolean
  children: React.ReactNode
}

export const HandleLoadingState = (props: Props) => {
  if (!props.loading) {
    return <>{props.children}</>
  }
  return (
    <div className="HandleLoadingState">
      <div className="container">
        <div className="HandleLoadingState__inner">
          <Spinner />
          <p>Loading...</p>
        </div>
      </div>
    </div>
  )
}
